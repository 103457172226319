import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { TEAMS } from "../Constants";
import { FaLinkedin } from "react-icons/fa6";

const Team = () => {
  const [selectedYear, setSelectedYear] = useState("2023");
  const [showAll, setShowAll] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [currentMemberIndex, setCurrentMemberIndex] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const uniqueYears = [...new Set(TEAMS.map((team) => team.year))].sort(
    (a, b) => b - a
  );

  const handleTabClick = (year) => {
    setSelectedYear(year);
    setShowAll(false);
    setCurrentMemberIndex(0);
  };

  const getTeamsForSelectedYear = () => {
    const teams = TEAMS.filter((team) => team.year === selectedYear);
    const startIndex = currentMemberIndex % teams.length;
    const slicedMembers = [];
    let currentIndex = startIndex;

    while (slicedMembers.length < (isMobileView ? 1 : 3)) {
      slicedMembers.push(teams[currentIndex]);
      currentIndex = (currentIndex + 1) % teams.length;
      if (currentIndex === startIndex) break;
    }

    return showAll ? teams : slicedMembers;
  };

  return (
    <div id="team" className="px-2 py-10 text-center relative">
      <motion.span
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="raleway text-2xl text-gray-900 font-bold underline underline-offset-3 decoration-8 decoration-[#830029] dark:decoration-dark:decoration-[#830029] mt-5"
      >
        Our Team
      </motion.span>

      <div className="mx-auto max-w-6xl relative">
        <div
          role="tablist"
          className="tabs tabs-bordered mt-8 flex justify-center"
        >
          {uniqueYears.map((year) => (
            <motion.a
              key={year}
              role="tab"
              className={`tab ${selectedYear === year ? "tab-active" : ""}`}
              onClick={() => handleTabClick(year)}
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              {year}
            </motion.a>
          ))}
        </div>
        <motion.ul
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="mt-16 grid grid-cols-1 gap-6 text-center text-slate-700 md:grid-cols-3 relative"
        >
          {getTeamsForSelectedYear().map((team, index) => (
            <motion.li
              key={`${team.year}-${index}`}
              className="rounded-xl bg-white mb-10 px-6 py-8 shadow-md"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: index * 0.2 }}
            >
              <img
                src={team.image}
                alt=""
                loading="lazy"
                // style={{ height: "250px", width: "250px", background: "cover" }}
              />
              <h3 className="my-3 hammersmith-one-regular font-display font-medium">
                {team.name}
              </h3>
              <p className="text-gray-700 mt-3 raleway">{team.description}</p>
              <a
                href={team.linkedIn}
                target="_blank"
                className="flex justify-center"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="h-8 w-8 mx-2" />
              </a>
            </motion.li>
          ))}
        </motion.ul>
      </div>
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
        <button
          onClick={() => setShowAll(!showAll)}
          className="bg-gray-900 text-white px-4 py-2 rounded-md flex items-center"
        >
          {showAll ? "Show less" : "View More"}
          {showAll ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 ml-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 8a.75.75 0 01.53.22l4 4a.75.75 0 11-1.06 1.06L10 9.06l-3.47 3.47a.75.75 0 11-1.06-1.06l4-4a.75.75 0 01.53-.22z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 ml-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 12a.75.75 0 01-.53-.22l-4-4a.75.75 0 011.06-1.06L10 10.94l3.47-3.47a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-.53.22z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default Team;
