import React from "react";
import {
  FaSquareXTwitter,
  FaSquareInstagram,
  FaLinkedin,
  FaFacebook,
} from "react-icons/fa6";

const Hero = () => {
  return (
    <div id="home" className="hero min-h-screen z-1 bg-gray-900 relative">
      <div className="bg-white absolute top-0 left-0 w-full h-full"></div>
      <div className="hero-content p-3 flex-col lg:flex-row-reverse items-center">
        <img
          src="/logo-white.png"
          alt="logo"
          className="max-w-sm rounded-lg  mb-8  lg:ml-20"
          loading="lazy"
        />
        <div className="lg:mr-20">
          <h1 className="text-5xl  font-bold text-black text-stroke-white">
            WELCOME TO
            <br />
            <br />
            <span className=""> APGSA</span>
          </h1>
          <h2 className="text-3xl font-bold text-black text-stroke-black">
            <span className="text-[#830029]">A</span>sper{" "}
            <span className="text-[#830029]">P</span>rofessional{" "}
            <span className="text-[#830029]">G</span>raduate{" "}
            <span className="text-[#830029]">S</span>tudents{" "}
            <span className="text-[#830029]">A</span>ssociation
          </h2>
        </div>
      </div>
      <div className="social-links flex justify-center bottom-0 absolute left-0 w-full py-4">
        <a
          href="https://twitter.com/apgsa_asper"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaSquareXTwitter className="w-10 h-10 mx-2" />
        </a>
        <a
          href="https://www.instagram.com/apgsa.asper/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaSquareInstagram className="w-10 h-10 mx-2" />
        </a>
        <a
          href="https://www.linkedin.com/company/asper-professional-gsa/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin className="h-10 w-10 mx-2" />
        </a>
        <a
          href="https://www.facebook.com/asperprofessionalgsa/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook className="w-10 h-10 mx-2" />
        </a>
      </div>
    </div>
  );
};

export default Hero;
