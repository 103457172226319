import React from "react";
import { motion } from "framer-motion";

const Contact = () => {
  return (
    <div id="contact">
      {" "}
      <motion.span
        initial={{ opacity: 15, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 3 }}
        className="raleway text-2xl text-gray-900 font-bold underline underline-offset-3 decoration-8 decoration-[#830029] dark:decoration-dark:decoration-[#830029] flex items-center justify-center"
      >
        Contact Us
      </motion.span>
      <div className="flex items-center justify-center">
        <div className="flex  items-center  justify-center mt-10 bg-white  ">
          <div className=" sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <div className="p-6 mr-2 bg-gray-100 dark:bg-white sm:rounded-lg">
                <p className="text-normal text-lg sm:text-2xl font-medium text-gray-600 dark:text-black mt-2">
                  Meeting by appoinment only
                </p>
                <div className="flex items-center text-lg font-bold mt-8 text-gray-600 dark:text-black">
                  <h3>Contact Us </h3>
                </div>
                <div className="flex items-center mt-4 text-gray-600 dark:text-black">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    className="w-8 h-8 text-gray-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <div className="ml-4 text-md tracking-wide font-semibold w-40">
                    7892255426
                  </div>
                </div>
                <div className="flex items-center mt-2 text-gray-600 dark:text-gray-400">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    className="w-8 h-8 text-gray-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <div className="ml-4 text-md tracking-wide font-semibold w-40 dark:text-black">
                    apgsa@gmail.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
